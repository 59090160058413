import { makeStyles } from "@material-ui/core";

export const MyAccountContainerStyles = makeStyles(theme => ({
  divider: { paddingLeft: "40px", paddingRight: "0px", paddingBottom: "70px" },
  infoContainer: {
    paddingLeft: "0px",
    paddingRight: "40px",
    paddingBottom: "70px",
    [theme.breakpoints.down("sm")]: {
      padding: "5px",
    },
  },
  titleContainer: {
    padding: "40px",
    paddingBottom: "70px",
    [theme.breakpoints.down("sm")]: {
      padding: "5px",
    },
  },
  user: {
    textAlign: "center",
  },
  amount: {
    textAlign: "center",
  },
  button: {
    marginRight: "12px",
  },
  orangeText: {
    color: theme.palette.primary.main,
  },
  greyText: {
    color: theme.palette.secondary.dark,
  },
  italicText: {
    fontStyle: "italic",
    color: theme.palette.secondary.dark,
  },
}));
