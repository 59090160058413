import { Text, View } from "@react-pdf/renderer";
import { styles } from "../styles/PdfStyles";
import React from "react";

export const BuyerDeclaration = () => (
  <View>
    <View style={styles.title}>
      <Text style={styles.title}>Buyer Declaration </Text>
    </View>
    <View style={styles.rowPaddingBottom14}>
      <View style={styles.buyerDeclarationBlock}>
        <Text style={styles.declarationText}>
          I declare that I am (or act on behalf of) the buyer of the goods described above at the prices stated, and that the buyer accepts the Full
          Terms & Conditions relating to the online auction, in particular those relating to liability, refunds and/or compensation.
        </Text>
        <Text style={styles.declarationText}>
          I understand it is my responsibility to inform DVLA of a change of vehicle ownership as soon as possible.
        </Text>
        <Text style={styles.declarationText}>I confirm that I have inspected the vehicle and am satisfied with its condition.</Text>
      </View>
      <View style={styles.nameSignatureBlock}>
        <Text style={styles.signatureText}>Print Name</Text>
        <Text style={styles.signatureText}>Signed</Text>
      </View>
      <View style={styles.dateBlock}>
        <Text style={styles.signatureText}>Date</Text>
      </View>
    </View>
  </View>
);
