import * as React from "react";
import * as Styles from "../styles/SearchBarStyles";
import { Grid, Button, TextField, InputAdornment, Theme, useMediaQuery, Container, Box } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { IVehicleSearchConfiguration } from "../../../interfaces/search/IVehicleSearchConfiguration";
import * as SearchService from "../../../services/SearchService";
import { SearchBarOption } from "./SearchBarOption";
import { ISearchBarProps } from "../../../interfaces/search/ISearchBarProps";
import { DistanceOptions } from "../../../interfaces/search/ISearchOptions";
import { SearchResultsTopBar } from "./SearchResultsTopBar";

export const SearchBar: React.FC<ISearchBarProps> = ({ options, loadingSearchResults, searchOptionsUpdated, resetHandler, orderByChanged }) => {
  const classes = Styles.SearchBarStyles();

  const [searchOptions, setSearchOptions] = React.useState(options);
  const [configuration, setConfiguration] = React.useState<IVehicleSearchConfiguration>();
  const [textSearch, setTextSearch] = React.useState<string>(searchOptions.searchText);

  React.useEffect(() => {
    async function getSearchConfigurationOptions() {
      const response = await SearchService.SearchConfiguration(searchOptions);
      const config: IVehicleSearchConfiguration = response.parsedBody as IVehicleSearchConfiguration;
      if (config !== undefined) {
        setConfiguration(config);
      }
    }
    getSearchConfigurationOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchOptions]);

  React.useEffect(() => {
    setSearchOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const handleSearchOptionSet = (property: string, value: string) => {
    searchOptionsUpdated({ ...searchOptions, [property]: value });
  };

  const handleSearchTextChange = (value: string) => {
    setTextSearch(value);
  };

  const handleSearch = () => {
    var options = { ...searchOptions, searchText: textSearch };
    searchOptionsUpdated(options);
  };

  const xs = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));

  return (
    <Box className={classes.box}>
      {configuration !== undefined && (
        <Container className={classes.container}>
          <Grid
            container
            spacing={1}
            alignItems={xs ? undefined : "center"}
            alignContent={xs ? undefined : "center"}
            justify={xs ? undefined : "flex-start"}
            style={{ paddingBottom: "8px" }}
          >
            <Grid item>
              <SearchBarOption
                displayName="Auction"
                propertyName="auction"
                options={configuration.lotTypes}
                selectedItemName={searchOptions.auction}
                disable={loadingSearchResults}
                onSelectedEvent={handleSearchOptionSet}
              />
            </Grid>
            <Grid item>
              <SearchBarOption
                displayName="Category"
                propertyName="damageCategory"
                options={configuration.categories}
                disable={loadingSearchResults}
                selectedItemName={searchOptions.damageCategory}
                onSelectedEvent={handleSearchOptionSet}
              />
            </Grid>
            <Grid item>
              <SearchBarOption
                displayName="Distance"
                propertyName="distance"
                disable={loadingSearchResults}
                options={DistanceOptions}
                selectedItemName={searchOptions.distance}
                onSelectedEvent={handleSearchOptionSet}
              />
            </Grid>
            <Grid item>
              <SearchBarOption
                displayName="Make"
                propertyName="make"
                disable={loadingSearchResults}
                options={configuration.makes}
                selectedItemName={searchOptions.make}
                onSelectedEvent={handleSearchOptionSet}
              />
            </Grid>
            <Grid item>
              <SearchBarOption
                displayName="Transmission"
                propertyName="transmissionType"
                disable={loadingSearchResults}
                options={configuration.transmissions}
                selectedItemName={searchOptions.transmissionType}
                onSelectedEvent={handleSearchOptionSet}
              />
            </Grid>
            <Grid item>
              <SearchBarOption
                displayName="Fuel"
                propertyName="fuelType"
                disable={loadingSearchResults}
                options={configuration.fuelTypes}
                selectedItemName={searchOptions.fuelType}
                onSelectedEvent={handleSearchOptionSet}
              />
            </Grid>
            <Grid item>
              <SearchBarOption
                displayName="Seller"
                propertyName="seller"
                disable={loadingSearchResults}
                options={configuration.sellers}
                selectedItemName={searchOptions.seller}
                onSelectedEvent={handleSearchOptionSet}
              />
            </Grid>
            <Grid item>
              <SearchBarOption
                displayName="Year"
                propertyName="year"
                disable={loadingSearchResults}
                options={configuration.years}
                selectedItemName={searchOptions.year}
                onSelectedEvent={handleSearchOptionSet}
              />
            </Grid>
            {/* REMOVED FOR COMMERCIAL CHANGES IN CR28 */}
            {/* <Grid item>
              <SearchBarOption
                displayName="Subscription"
                propertyName="subscription"
                disable={loadingSearchResults}
                options={configuration.subscriptions}
                selectedItemName={searchOptions.subscription}
                onSelectedEvent={handleSearchOptionSet}
              />
            </Grid> */}
          </Grid>
          <Grid
            container
            spacing={1}
            alignItems={xs ? undefined : "center"}
            alignContent={xs ? undefined : "center"}
            justify={xs ? undefined : "center"}
            style={{ paddingBottom: "16px" }}
          >
            <Grid item>
            <SearchBarOption
                displayName="Starts & Drives"
                propertyName="startDrive"
                disable={loadingSearchResults}
                options={configuration.startDrive}
                selectedItemName={searchOptions.startDrive}
                onSelectedEvent={handleSearchOptionSet}
              />
            </Grid>
            <Grid item>
              <Grid container alignItems={xs ? undefined : "center"} alignContent={xs ? undefined : "center"}>
                <TextField
                  size="small"
                  style={{ backgroundColor: "white" }}
                  disabled={loadingSearchResults}
                  id="textSearch"
                  placeholder="Search e.g. Blue"
                  onChange={e => handleSearchTextChange(e.target.value)}
                  value={textSearch}
                  onKeyDown={e => {
                    if (e.key === "Enter") {
                      handleSearch();
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment style={{ cursor: "pointer" }} onClick={() => handleSearch()} position="end">
                        <SearchIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid item>
              <SearchResultsTopBar searchOptions={options} orderByChanged={orderByChanged} />
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" className={classes.resetButton} onClick={resetHandler}>
                Reset
              </Button>
            </Grid>
          </Grid>
        </Container>
      )}
    </Box>
  );
};
