import React from "react";
import * as Styles from "./styles/UpdateNotificationStyles";
import { LoadingComponent } from "../loading/LoadingComponent";
import { useVersionedData, useReadonlyData } from "../../services/useVersionedData";
import { GetClientSettings } from "../../services/SettingsService";
import { ISalvageClientSettings } from "../../interfaces/ISalvageClientSettings";
import { Button, Typography, Box, Grid, Tooltip } from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import SmsIcon from "@material-ui/icons/Sms";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import EmailIcon from "@material-ui/icons/Email";

// Source for all available notification rules
import {
  NotificationPreferenceType,
  INotificationOptionSet,
  getNotificationOptionName,
  NotificationOptionAvailability,
} from "./helpers/RuleIdentifiers";

// Data structure to manipulate
import { INotificationPreferences } from "../../interfaces/bidders/INotificationPreferences";
import { useSnackbar } from "notistack";

const ClientSettings: ISalvageClientSettings = GetClientSettings();

interface CheckBoxesForRuleProps {
  definition: INotificationOptionSet;
  onChange(ruleIdentifier: string, destination: NotificationPreferenceType, checked: boolean): void;
  isSelected(ruleIdentifier: string, destination: NotificationPreferenceType): boolean;
}

const CheckBoxesForRules: React.FC<CheckBoxesForRuleProps> = ({ definition, onChange, isSelected }) => {
  const [togglePreferenceType, setTogglePreferenceType] = React.useState<NotificationPreferenceType | null>(null);
  const classes = Styles.UpdateNotificationStyles();

  const handleAlignment = (event: React.MouseEvent<HTMLElement>, newTogglePreference: NotificationPreferenceType | null) => {
    setTogglePreferenceType(newTogglePreference);

    var sms = newTogglePreference ? newTogglePreference === NotificationPreferenceType.Sms : false;
    var whatsApp = newTogglePreference ? newTogglePreference === NotificationPreferenceType.WhatsApp : false;

    if (sms) {
      onChange(definition.ruleIdentifier, NotificationPreferenceType.Sms, true);
    } else if (whatsApp) {
      onChange(definition.ruleIdentifier, NotificationPreferenceType.WhatsApp, true);
    } else {
      if (togglePreferenceType) onChange(definition.ruleIdentifier, togglePreferenceType, false);
    }
  };

  React.useEffect(() => {
    if (isSelected(definition.ruleIdentifier, NotificationPreferenceType.Sms)) {
      setTogglePreferenceType(NotificationPreferenceType.Sms);
    }
    if (isSelected(definition.ruleIdentifier, NotificationPreferenceType.WhatsApp)) {
      setTogglePreferenceType(NotificationPreferenceType.WhatsApp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [definition]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={9} className={classes.labelContainer}>
        <Typography>{getNotificationOptionName(definition.ruleIdentifier)}</Typography>
      </Grid>
      <Grid item xs={3}>
        <ToggleButtonGroup value={togglePreferenceType} exclusive className={classes.toggleGroup} onChange={handleAlignment} size="small">
          <Tooltip title={"Email"}>
            <div>
              <ToggleButton
                value={NotificationPreferenceType.Email}
                onClick={() => {
                  onChange(
                    definition.ruleIdentifier,
                    NotificationPreferenceType.Email,
                    !isSelected(definition.ruleIdentifier, NotificationPreferenceType.Email)
                  );
                }}
              >
                <EmailIcon color={isSelected(definition.ruleIdentifier, NotificationPreferenceType.Email) ? "primary" : undefined} />
              </ToggleButton>
            </div>
          </Tooltip>
          {definition.smsAvailability !== NotificationOptionAvailability.Unavailable && (
            <Tooltip title="Text Message">
              <ToggleButton value={NotificationPreferenceType.Sms}>
                <SmsIcon color={togglePreferenceType === NotificationPreferenceType.Sms ? "primary" : undefined} />
              </ToggleButton>
            </Tooltip>
          )}
          {definition.whatsAppAvailability !== NotificationOptionAvailability.Unavailable && (
            <Tooltip title="WhatsApp">
              <ToggleButton value={NotificationPreferenceType.WhatsApp}>
                <WhatsAppIcon color={togglePreferenceType === NotificationPreferenceType.WhatsApp ? "primary" : undefined} />
              </ToggleButton>
            </Tooltip>
          )}
        </ToggleButtonGroup>
      </Grid>
    </Grid>
  );
};

export const NotificationPreferencesContainer: React.FC = () => {
  const classes = Styles.UpdateNotificationStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [data, setData, isLoading, , put, refresh] = useVersionedData<INotificationPreferences[]>(
    `${ClientSettings.UsersPrivateApiUrl}/biddernotifications/preferences/`,
    () => enqueueSnackbar(`There was a problem with your preferences. Please try again later.`, { variant: "error" })
  );
  const [available, isLoadingAvailable] = useReadonlyData<INotificationOptionSet[]>(
    `${ClientSettings.UsersPrivateApiUrl}/biddernotifications/availableoptions/`
  );

  // Comparator for notification preferences being the same.
  const isSame = (prefA: INotificationPreferences, prefB: INotificationPreferences) => {
    return prefA.destinationType === prefB.destinationType && prefA.ruleIdentifier === prefB.ruleIdentifier;
  };

  // Function to ensure a preference exists in the data
  const ensurePref = (pref: INotificationPreferences) => {
    if (data && !data.some(existing => isSame(existing, pref))) {
      // Logic to enforce either/or on sms and whatsapp.
      const oppositeType: INotificationPreferences = {
        ruleIdentifier: pref.ruleIdentifier,
        destinationType:
          pref.destinationType === NotificationPreferenceType.Sms ? NotificationPreferenceType.WhatsApp : NotificationPreferenceType.Sms,
      };
      const dataExceptOppositeType = data.filter(existing => !isSame(existing, oppositeType));

      setData([...dataExceptOppositeType, pref]);
    }
  };

  // Function to remove a preference, if it exists in the data
  const removePref = (pref: INotificationPreferences) => {
    if (data && data.some(existing => isSame(existing, pref))) {
      setData(data.filter(existing => !isSame(existing, pref)));
    }
  };

  // Call ensurePref or removePref as appropriate on change of checkbox.
  const onChange = (ruleIdentifier: string, destination: NotificationPreferenceType, checked: boolean) => {

    if (data) {
      const pref = {
        ruleIdentifier: ruleIdentifier,
        destinationType: destination,
      };
      if (checked) {
        ensurePref(pref);
      } else {
        removePref(pref);
      }
    }
  };

  // Checkbox selected state is just a test of whether the data has that selection as present or not.
  const isSelected = (ruleIdentifier: string, destination: NotificationPreferenceType) =>
    data !== null && data.some(existing => isSame(existing, { ruleIdentifier: ruleIdentifier, destinationType: destination }));



  const submit = () => {
    let submit: boolean = false;

    data?.forEach(item => {
      if (item.ruleIdentifier == 'auctionwon') {
        submit = true;
      }
    });

    if (submit) {
      put();
    }else{
      enqueueSnackbar("Please select a notification preference for 'Notify when you have won an auction.'", { variant: "error" })
    }
  }


  return (
    <>
      {(isLoading || isLoadingAvailable) && <LoadingComponent label={"Loading notification preferences"} />}
      {!isLoading && (data === null || available === null) && <></>}
      {!isLoading && data !== null && available !== null && (
        <div className={classes.paper}>
          <h2> Manage Notifications </h2>

          {available.map((availableOption, index) => (
            <CheckBoxesForRules definition={availableOption} onChange={onChange} isSelected={isSelected} key={index} />
          ))}

          <Box display="flex" justifyContent="center" mt={2}>
            <Box mx={1}>
              <Button variant="contained" color="primary" disableElevation onClick={() => submit()}>
                Save settings
              </Button>
            </Box>
            <Button variant="contained" color="primary" disableElevation onClick={() => refresh()}>
              Undo
            </Button>
          </Box>
        </div>
      )}
    </>
  );
};
