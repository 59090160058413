import * as React from "react";
import { Typography, Box, Grid, FormControlLabel, Checkbox, Button } from "@material-ui/core";
import * as BidderService from "../../services/BidderService";
import { useSnackbar } from "notistack";

export const UpdateTermsForm: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [agreeToTandCs, setAgreeToTandCs] = React.useState(false);

    const continueButtonClick = () => {
        console.log('clicky: ', agreeToTandCs);

        //if (agreeToTandCs) {
            BidderService.UpdateTermsAndConditionsAcceptance()
                .then(result => {
                    window.location.href = '/';
                })
                .catch(async response => {
                    enqueueSnackbar("Sorry, but something went wrong while trying to save your details. Please try again.", { variant: "error" });
                });
        // } else {
        //     enqueueSnackbar("Please accept the Terms and Conditions before continuing.", { variant: "error" });
        // }

    };


    return (
        <>

            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <h3>Terms &amp; Conditions</h3>
                    <p>We have recently updated our terms and conditions, in order to continue please accept these new terms.</p>
                    <p>
                        By clicking this button I agree that I am at least 18 years of age and that I have read and agreed to the{" "}
                        <a href="TermsConditions" target="_blank">
                            terms and conditions
                        </a>.
                    </p>                    
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                    <Button color="primary" variant="contained" onClick={() => { continueButtonClick(); }}>
                        I Accept
                    </Button>
                </Grid>
            </Grid>




        </>
    );
};
