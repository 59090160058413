import { makeStyles } from "@material-ui/core/styles";

export const SearchComponentStyles = makeStyles(theme => ({
  searchBarContainer: {
    width: "100%",
  },
  resultsBar: {
    marginTop: "10px;",
    padding: "10px;",
    marginBottom: "10px;",
    position: "relative",
  },
  resultBarOrderByComponent: {
    float: "right",
    margin: 0,
  },
  paper: {
    padding: "4px 8px",
  },
  noXPadding: {
    paddingLeft: "0px",
    paddingRight: "0px",
  },
}));
