import { makeStyles } from "@material-ui/core/styles";

export const FooterStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#393939",
    flexGrow: 1,
    marginTop: "32px",
    padding: "64px 0 64px 0",
  },
  container: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: "#D1D1D1",
  },
  containerTitle: {
    color: "#D1D1D1",
  },
  containerItemGrey: {
    fontSize: "0.8rem",
    color: "#858585",
  },
  containerItem: {
    fontSize: "0.8rem",
  },
  socialContainer: {
    display: "flex",
    marginBottom: "16px",
    width: "100%",
  },
  social: {
    margin: theme.spacing(1),
  },
  versionContainer: {
    position: "relative",
  },
  versionText: {
    position: "absolute",
    bottom: "0",
    right: "0",
    padding: "0",
    margin: "4px 12px",
    color: "white",
    fontSize: "8px",
  },
  logo: {
    display: "flex",
    height: "48px",
  },
}));
