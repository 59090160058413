import { IWatchlistItem } from "../watchlist/IWatchlistItem";
import { AccountType, IBidder } from "./IBidder";

export interface IBidderState {
  bidder: IBidder;
  bidderLoaded: boolean;
  isRegistered: boolean | undefined;
  isSubscribed: boolean | undefined;
  currentTermsAccepted: boolean | undefined;
  termsEnabled: boolean | undefined;
  getBidder: (ignoreLoading: boolean) => void;
  checkWatchlist: (WatchlistItem: IWatchlistItem) => boolean;
  addToWatchlist: (lotId: string, persistToAPI?: boolean) => Promise<void>;
  removeFromWatchlist: (lotId: string) => Promise<void>;
  bidderRegistered: (bidder: IBidder) => void;
  bidderAccountTypeSelect: (accountType: AccountType) => void;
  verificationDocumentsSubmitted: () => void;
}

export const BidderState: IBidderState = {
  bidder: {} as IBidder,
  bidderLoaded: false,
  isRegistered: undefined,
  currentTermsAccepted: undefined,
  termsEnabled: undefined,
  getBidder: (ignoreLoading: boolean = false) => { },
  checkWatchlist: (WatchlistItem: IWatchlistItem) => false,
  addToWatchlist: (lotId: string) => {
    return new Promise(resolve => {
      resolve();
    });
  },
  removeFromWatchlist: (lotId: string) => {
    return new Promise(resolve => {
      resolve();
    });
  },
  isSubscribed: undefined,
  bidderRegistered: (registeredBidder: IBidder) => { },
  bidderAccountTypeSelect: (accountType: AccountType) => { },
  verificationDocumentsSubmitted: () => { },
};
