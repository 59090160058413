import React from "react";
import { LoadingComponent } from "../loading/LoadingComponent";
import { ProcessPayZoneResponse } from "../../services/PaymentService";
import { IPayZoneInitialResponse } from "../../interfaces/payments/IPayzoneInitialResponse";
import { IPaymentResult } from "../../interfaces/payments/IPaymentResult";
import { OrderStatus } from "../../interfaces/payments/OrderStatus";
import { PaymentResultComponent } from "./PaymentResultComponent";

const PaymentResultContainer: React.FC = ({ children }) => {
  const isPayzoneRequest = () => {
    //  return true;
    return window.location.search.startsWith("?HashDigest=");
  };

  const [result, setResult] = React.useState<IPaymentResult | undefined>();
  const [loadingPaymentResult, setLoadingPaymentResult] = React.useState(false);
  // This can be used for testing - set the state above to something like this:
  // {
  //   message: "Thank you for your purchase which has been processed successfully",
  //   orderStatus: OrderStatus.Succeeded,
  //   order: {
  //     orderDescription: "1 year subscription",
  //     amountExclVAT: 1000,
  //     includesVAT: true,
  //     orderId: "sube539d6a85afb11ebae930242ac130002",
  //     total: 6000,
  //     orderType: OrderTypes.Subscription,
  //     orderData: "Order Data"
  //   },
  // });
  React.useEffect(() => {
    if (loadingPaymentResult) {
      ProcessPayZoneResponse(parseQueryString())
        .then(data => {
          setResult(data.parsedBody);
        })
        .catch(e => {
          if (e.status === 401) {
            // TODO... we need to handle this better. Some how need to re-authenticate without losing all the token data.
            setResult({
              message: "You were not authenticated....",
              orderStatus: OrderStatus.Error,
              order: undefined,
            });
          } else {
            console.log(e);
            setResult({
              message: "There was an error passing the result from the payment provider to the servers.",
              orderStatus: OrderStatus.Error,
              order: undefined,
            });
          }
        })
        .finally(() => setLoadingPaymentResult(false));
    }
  }, [loadingPaymentResult]);

  const parseQueryString = (): IPayZoneInitialResponse => {
    var str = window.location.search;
    const objURL: any = {};
    [...str.matchAll(/([^?=&]+)(=([^&]*))?/g)].forEach(match => {
      objURL[match[1]] = match[3];
    });
    return objURL as IPayZoneInitialResponse;
  };

  if (!result && isPayzoneRequest()) {
    if (loadingPaymentResult) {
      return <LoadingComponent label="Processing Payment... please wait" />;
    } else {
      setLoadingPaymentResult(true);
    }
  }
  return (
    <>
      {result && <PaymentResultComponent result={result} crossRef={parseQueryString().crossReference} />}
      {children}
    </>
  );
};

export default PaymentResultContainer;
