import React from "react";
import { Grid } from "@material-ui/core";
import * as Styles from "./styles/MyAccountContainerStyles";
import { MemberDetails } from "../../services/MemberService";

interface AccountRegisteredWithDetailsProps {
  member: MemberDetails;
}

const AccountRegisteredWithDetails: React.FC<AccountRegisteredWithDetailsProps> = ({ member }) => {
  const classes = Styles.MyAccountContainerStyles();

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <h4 className={classes.orangeText}>COMPANY</h4>
      </Grid>
      <Grid item xs={6}>
        <h4 className={classes.greyText}>{member.name}</h4>
      </Grid>
      <Grid item xs={6}>
        <h4 className={classes.orangeText}>ADDRESS</h4>
      </Grid>
      <Grid item xs={6}>
        <h4 className={classes.greyText}>
          {[member?.line1, member?.line2, member?.line3, member?.city, member?.county, member?.postcode].filter(e => e).join(" ")}
        </h4>
      </Grid>
      <Grid item xs={6}>
        <h4 className={classes.orangeText}>TELEPHONE NUMBER:</h4>
      </Grid>
      <Grid item xs={6}>
        <h4 className={classes.greyText}>{member.phoneNumber}</h4>
      </Grid>
      <Grid item xs={6}>
        <h4 className={classes.orangeText}>EMAIL</h4>
      </Grid>
      <Grid item xs={6}>
        <h4 className={classes.greyText}>{member.email}</h4>
      </Grid>
    </Grid>
  );
};

export default AccountRegisteredWithDetails;
