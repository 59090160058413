import * as React from "react";
import { ISearchResultsTopBarProps } from "../../../interfaces/search/ISearchResultsTopBarProps";
import { Typography, Select, MenuItem, TextField, InputAdornment, IconButton, Grid, Paper } from "@material-ui/core";
import FilterList from "@material-ui/icons/FilterList";
import { SearchOrderingOptions } from "../../../interfaces/search/ISearchBarOrderProps";
import { SearchComponentStyles } from "../styles/SearchComponentStyles";
import { OrderBy } from "../../../interfaces/search/ISearchOptions";
import { AppContext } from "../../../contexts/AppContext";

export const SearchResultsTopBar: React.FC<ISearchResultsTopBarProps> = props => {
  const classes = SearchComponentStyles();
  const context = React.useContext(AppContext);

  const [orderBySelected, setOrderBySelected] = React.useState<OrderBy>(props.searchOptions.orderBy);
  const [location, setLocation] = React.useState<string>("");

  React.useEffect(() => {
    setOrderBySelected(props.searchOptions.orderBy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.searchOptions.orderBy]);

  React.useEffect(() => {
    const bidderPostcode: string = context.bidderState.bidder?.address?.postcode ?? "";
    setLocation(bidderPostcode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.bidderState.bidder]);

  const handleOrderChange = (event: any) => {
    var selected = event.target.value as OrderBy;
    setOrderBySelected(selected);
    props.orderByChanged(selected, location);
  };

  const handleLocationChange = () => {
    props.orderByChanged(orderBySelected, location);
  };

  const showDistanceValidationMessage = () => {
    return location === "" && (props.searchOptions.orderBy === OrderBy.DistanceAsc || props.searchOptions.orderBy === OrderBy.DistanceDesc);
  };

  const distanceValidationMessage = () => {
    var message = "";

    if (showDistanceValidationMessage()) {
      message = "Enter a postcode to ";

      if (props.searchOptions.distance !== "") {
        message = `${message} filter`;
        if (props.searchOptions.orderBy === OrderBy.DistanceAsc || props.searchOptions.orderBy === OrderBy.DistanceDesc) {
          message = `${message} and sort`;
        }
      } else if (props.searchOptions.orderBy === OrderBy.DistanceAsc || props.searchOptions.orderBy === OrderBy.DistanceDesc) {
        message = `${message} sort`;
      }

      message = `${message} by distance`;
    }

    return message;
  };

  return (
    <Paper variant="outlined" component="form" className={classes.paper}>
      <Grid container justify="space-between">
        <Grid item>
          <Typography className={classes.resultBarOrderByComponent} variant="subtitle1" display="inline" gutterBottom>
            Sort by:
          </Typography>
        </Grid>
        <Grid item>
          <Select value={orderBySelected} onChange={handleOrderChange} displayEmpty style={{ marginLeft: "12px", height: "32px" }}>
            {SearchOrderingOptions.map(option => (
              <MenuItem key={option.id} value={option.id} selected={option.id === orderBySelected}>
                {option.displayTitle}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item>
          <TextField
            style={{ height: "32px", maxWidth: "150px" }}
            value={location}
            error={showDistanceValidationMessage()}
            helperText={distanceValidationMessage()}
            onChange={value => {
              setLocation(value.target.value.toString());
            }}
            onKeyPress={event => {
              if (event.key === "Enter") {
                handleLocationChange();
              }
            }}
            placeholder="Postcode"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton onClick={handleLocationChange}>
                    <FilterList color="primary" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          ></TextField>
        </Grid>
      </Grid>
    </Paper>
  );
};
