export enum NotificationOptionAvailability {
  Unavailable = 0,
  OptIn = 1,
}

export interface INotificationOptionSet {
  ruleIdentifier: string;
  smsAvailability: NotificationOptionAvailability;
  whatsAppAvailability: NotificationOptionAvailability;
}

export enum NotificationPreferenceType {
  Unknown,
  Sms,
  Email,
  WhatsApp,
}

export const getNotificationOptionName = (ruleIdentifier: string) => {
  switch (ruleIdentifier) {
    case "bidplacedwinning":
      return "Notify when you've placed a bid and are winning";
    case "bidplacedoutbid":
      return "Notify when you've been outbid";
    case "auctionendingtoday":
      return "Notify when an auction you have bid on is ending today";
    case "auctionendingonehour":
      return "Notify when an auction you have bid on is ending in an hour";
    case "auctionendingfiveminutes":
      return "Notify when an auction you have bid on is ending in five minutes";
    case "watchedauctionendingtoday":
      return "Notify when an auction you have watched is ending today";
    case "watchedauctionendingonehour":
      return "Notify when an auction you have watched is ending in an hour";
    case "watchedauctionendingfiveminutes":
      return "Notify when an auction you have watched is ending in five minutes";
    case "auctionwon":
      return "Notify when you have won an auction.";
    case "auctionsuspended":
      return "Notify when an auction you have watched/bid has been suspended.";
    case "auctionunsuspended":
      return "Notify when an auction you have watched/bid has been unsuspended.";
    case "outbidded":
      return "Notify when you have been outbid.";
    case "paymentoverdue":
      return "Notify when you have an overdue payment after three days.";
    case "subscriptionendingonemonth":
      return "Notify when your subscription is ending in one month.";
    case "subscriptionendingoneweek":
      return "Notify when your subscription is ending in one week.";
    case "subscriptionendingtoday":
      return "Notify when your subscription is ending today.";
    case "subscriptionendedyesterday":
      return "Notify if your subscription ended yesterday.";
    case "subscriptionendedoneweekago":
      return "Notify if your subscription ended one week ago.";
    case "subscriptionendedonemonthago":
      return "Notify if your subscription ended one month ago.";
    case "auctionlost":
      return "Notify when you have lost an auction";
    case "bidconfirmedoutbid":
      return "Notify when you've placed a bid";
    case "auctionrelisted":
      return "Notify when an auction you have watched has been relisted.";
    default:
      return ruleIdentifier;
  }
};
