import * as React from "react";
import * as Styles from "./styles/InformationNavigationStyle";
import { Grid, Container, Box, Typography, Hidden } from "@material-ui/core";
import { Navigation } from "./Navigation";
import { IInitialState, AppContext } from "../../contexts/AppContext";
import { StyleContext } from "../../contexts/StyleContext";

const InformationNavigation: React.FC<{
  title: string;
}> = ({ title }) => {
  const context = React.useContext(StyleContext);
  const classes = Styles.InformationNavigationStyles(context.theme)();

  return (
    <AppContext.Consumer>
      {(context: IInitialState) => (
        <div className={classes.root}>
          <div className={classes.navigationRoot}>
            <Container maxWidth={"lg"} className={classes.navigationContainer}>
              <Navigation lightTheme={true} context={context} />
            </Container>
          </div>
          <Hidden mdDown>
            <Container maxWidth={"lg"} fixed className={classes.contentContainer}>
              <Grid container spacing={3} alignItems="center">
                <Grid item lg={12} md={12} sm={12} xs={false}>
                  <Box alignItems="center" className={classes.heroTextContainer}>
                    <Hidden mdDown>
                      <Typography variant="h2" className={classes.heroText}>
                        {title}
                      </Typography>
                    </Hidden>
                    <Hidden lgUp smDown>
                      <Typography variant="h3" className={classes.heroText}>
                        {title}
                      </Typography>
                    </Hidden>
                    <Hidden mdUp xsDown>
                      <Typography variant="h4" className={classes.heroText}>
                        {title}
                      </Typography>
                    </Hidden>
                  </Box>
                  <Grid item lg={2} md={2} sm={2} xs={false}></Grid>
                </Grid>
              </Grid>
            </Container>
          </Hidden>
        </div>
      )}
    </AppContext.Consumer>
  );
};

export default InformationNavigation;
