import * as React from "react";
import { useLocation, useHistory } from "react-router-dom";
import * as queryString from "query-string";
import { useSnackbar } from "notistack";
import * as SearchService from "../../services/SearchService";
import { SearchComponentStyles } from "./styles/SearchComponentStyles";
import { SearchBar } from "./SearchBar/SearchBar";
import {
  ISearchOptions,
  OrderBy,
  SetSearchOption,
  AuctionSearchPageSize,
  EditorPickSearch,
  SearchOption,
  editorPickSearchString,
  QuickSearch,
  quickSearchString
} from "../../interfaces/search/ISearchOptions";
import { Container, TablePagination, Grid, Typography, Chip } from "@material-ui/core";
import { LoadingComponent } from "../loading/LoadingComponent";
import { AuctionListings } from "../auction-listings/AuctionListings";
import GoogleGeocodeService from "../../services/GoogleGeocodeService";
import { AppContext } from "../../contexts/AppContext";
import { AuctionSearchResult } from "../../services/AuctionService";
import pluralize from "pluralize";
import { IMsalContext } from "../../authentication/MsalContext";
import { useMsal } from "../../authentication/MsalProvider";

export function SearchComponent() {
  const classes = SearchComponentStyles();
  const location = useLocation();
  const history = useHistory();
  const context = React.useContext(AppContext);
  const googleService = GoogleGeocodeService();
  const { enqueueSnackbar } = useSnackbar();
  const msal: IMsalContext = useMsal();

  const bidderLatitude = context.bidderState.bidder?.address?.latitude;
  const bidderLongitude = context.bidderState.bidder?.address?.longitude;
  const bidderPostcode = context.bidderState.bidder?.address?.postcode ?? "";
  const defaultSearchOptions: ISearchOptions = SetSearchOption(queryString.parse(location.search));

  const [loadingSearchResults, setLoadingSearchResults] = React.useState<boolean>(false);
  const [searchOptions, setSearchOptions] = React.useState<ISearchOptions>(defaultSearchOptions);
  const [distanceOrigin, setDistanceOrigin] = React.useState<string>(bidderPostcode);
  const [searchResultCount, setSearchResultCount] = React.useState<number>();
  const [searchResults, setSearchResults] = React.useState<Array<AuctionSearchResult>>([]);

  React.useEffect(() => {
    setDistanceOrigin(bidderPostcode);
    if (searchOptions.longitude === 0 && searchOptions.latitude === 0) {
      if (bidderLatitude && bidderLongitude) {
        setSearchOptions({
          ...searchOptions,
          latitude: bidderLatitude,
          longitude: bidderLongitude,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.bidderState.bidderLoaded]);

  const handleSearchFilterChange = (options: ISearchOptions) => {
    if (options.distance !== "" && options.distance !== "National" && distanceOrigin) {
      googleService
        .getDistance(distanceOrigin)
        .then((data: google.maps.GeocoderResult[]) => {
          setSearchOptions({
            ...options,
            pageNumber: 0,
            editorPickSearch: EditorPickSearch.None,
            quickSearch: QuickSearch.None,
            latitude: data[0].geometry.location.lat(),
            longitude: data[0].geometry.location.lng(),
          });
        })
        .catch(() => {
          enqueueSnackbar("Could not find your location.", {
            variant: "warning",
          });
        });
    } else {
      setSearchOptions({
        ...options,
        pageNumber: 0,
        editorPickSearch: EditorPickSearch.None,
        quickSearch: QuickSearch.None,
      });
    }
  };

  const handleOrderByChange = (order: OrderBy, origin: string | undefined) => {
    if (origin) {
      setDistanceOrigin(origin);
      googleService
        .getDistance(origin)
        .then((data: google.maps.GeocoderResult[]) => {
          setSearchOptions({
            ...searchOptions,
            pageNumber: 0,
            editorPickSearch: EditorPickSearch.None,
            quickSearch: QuickSearch.None,
            orderBy: order,
            latitude: data[0].geometry.location.lat(),
            longitude: data[0].geometry.location.lng(),
          });
        })
        .catch(() => {
          enqueueSnackbar("Could not find your location.", {
            variant: "warning",
          });
        });
    } else {
      setSearchOptions({
        ...searchOptions,
        pageNumber: 0,
        orderBy: order,
        editorPickSearch: EditorPickSearch.None,
        quickSearch: QuickSearch.None,
      });
    }
  };

  React.useEffect(() => {
    const getSearchListings = () => {
      setLoadingSearchResults(true);
      SearchService.SearchAuctions(searchOptions)
        .then(result => {
          if (result.parsedBody !== undefined) {
            setSearchResultCount(result.parsedBody.count);
            setSearchResults(result.parsedBody.results);
            setLoadingSearchResults(false);
          }
        })
        .catch(async () => {
          setLoadingSearchResults(false);
          enqueueSnackbar("Could not return search results, please try again.", {
            variant: "error",
          });
        });
    };

    //If the user is not logged in load search results
    //Else wait for the bidder to be loaded, to acquire location info.
    if (msal.instance.getAllAccounts().length === 0) {
      getSearchListings();
    } else if (context.bidderState.bidderLoaded) {
      getSearchListings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchOptions, context.bidderState.bidderLoaded]);

  React.useEffect(() => {
    const searchString = queryString.stringify(searchOptions);
    sessionStorage.setItem("search", searchString);
    history.replace({
      pathname: "/Search",
      search: searchString,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchOptions]);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setSearchOptions({
      ...searchOptions,
      pageNumber: newPage,
    });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchOptions({
      ...searchOptions,
      pageNumber: 0,
      pageSize: parseInt(event.target.value, 10),
    });
  };

  const handleResetOptions = () => {
    history.push({
      pathname: "/Search",
      search: queryString.stringify(SearchOption(bidderLatitude, bidderLongitude)),
    });
    setSearchOptions(SearchOption(bidderLatitude, bidderLongitude));
  };

  const paging = () => {
    return (
      searchResultCount !== undefined &&
      searchResultCount > 0 && (
        <TablePagination
          component="div"
          count={searchResultCount}
          page={searchOptions.pageNumber}
          rowsPerPage={searchOptions.pageSize}
          rowsPerPageOptions={[
            { value: AuctionSearchPageSize.Twenty, label: "20" },
            { value: AuctionSearchPageSize.Thirty, label: "30" },
            { value: AuctionSearchPageSize.Forty, label: "40" },
          ]}
          labelRowsPerPage="Rows"
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          nextIconButtonProps={{ style: { padding: 0 } }}
          backIconButtonProps={{ style: { padding: 0 } }}
          classes={{ toolbar: classes.noXPadding }}
        />
      )
    );
  };

  return (
    <>
      <SearchBar
        loadingSearchResults={loadingSearchResults}
        options={searchOptions}
        searchOptionsUpdated={handleSearchFilterChange}
        resetHandler={handleResetOptions}
        orderByChanged={handleOrderByChange}
      />
      <Container className={classes.searchBarContainer}>
        <Grid container>
          <Grid item xs={12}>
            {!loadingSearchResults && searchResultCount !== undefined && (
              <Typography variant="h4" component="h4" align="left" display="inline">
                {pluralize("Result", searchResultCount, true)}{" "}
                {searchOptions.editorPickSearch !== EditorPickSearch.None && (
                  <Chip
                    style={{ marginBottom: "3px" }}
                    color="primary"
                    label={editorPickSearchString(searchOptions.editorPickSearch)}
                    onDelete={handleResetOptions}
                  />
                )}

                {searchOptions.quickSearch !== QuickSearch.None && (
                  <Chip
                    style={{ marginBottom: "3px" }}
                    color="primary"
                    label={quickSearchString(searchOptions.quickSearch)}
                    onDelete={handleResetOptions}
                  />
                )}

                {searchOptions.bucketDetails && (
                  <Chip style={{ marginBottom: "3px" }} color="primary" label={searchOptions.bucketDetails} onDelete={handleResetOptions} />
                )}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            {paging()}
          </Grid>
          <Grid item xs={12}>
            {loadingSearchResults ? <LoadingComponent label="Loading search results" /> : <AuctionListings results={searchResults}></AuctionListings>}
          </Grid>
          <Grid item xs={12}>
            {paging()}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
