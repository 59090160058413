import React from "react";
import { Grid } from "@material-ui/core";
import { AuctionListItem } from "./AuctionListItem";
import { AuctionSearchResult } from "../../services/AuctionService";

export const AuctionListings: React.FC<{
  results: AuctionSearchResult[] | undefined;
}> = ({ results }) => {
  return (
    <Grid container spacing={2} direction="column" justify="center" alignItems="stretch">
      {results !== undefined &&
        results.map((item: AuctionSearchResult, index: number) => (
          <Grid item key={index}>
            <AuctionListItem item={item} index={index} />
          </Grid>
        ))}
    </Grid>
  );
};
