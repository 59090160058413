import { IAddress, AddressDefault } from "../IAddress";
import { IBusiness } from "./IBusiness";
import { IVerification, VerificationDefault } from "./IVerification";
import { INotificationPreferences, NotificationPreferencesDefault } from "./INotificationPreferences";
import { ISuspension } from "./ISuspension";

export interface IBidder {
  id: string;
  name: string;
  email: string;
  memberId: string;
  landlineNumber: string;
  mobileNumber: string;
  address: IAddress;
  bidderStatus: BidderStatus;
  verification: IVerification;
  business: IBusiness | null;
  watchlist: Array<string>;
  subscriptionStartDate: string;
  subscriptionEndDate: string;
  mailingListOptIn: boolean;
  notificationPreferences: INotificationPreferences[];
  accountType: AccountType;
  suspensionDetails: ISuspension[];
  currentTermsAccepted: boolean | undefined;
  termsEnabled: boolean | undefined;
}

export enum AccountType {
  Unknown = 0,
  Free = 1,
  Subscribed = 2,
  Member = 3,
}

export enum BidderStatus {
  Unknown = 0,
  NotRegistered = 1,
  AwaitingPayment = 2,
  AwaitingIdenfificaiton = 3,
  AwaitingValidation = 4,
  FailedValidation = 5,
  PotentialDuplicate = 6,
  ActiveFull = 7,
  ActiveFree = 8,
  Suspended = 9,
  SubscriptionExpired = 10,
  Member = 11,
}

export const BidderDefault: IBidder = {
  id: "",
  name: "",
  email: "",
  memberId: "",
  landlineNumber: "",
  mobileNumber: "",
  address: AddressDefault,
  bidderStatus: BidderStatus.Unknown,
  verification: VerificationDefault,
  business: null,
  watchlist: [],
  subscriptionEndDate: "",
  subscriptionStartDate: "",
  mailingListOptIn: false,
  notificationPreferences: [NotificationPreferencesDefault],
  accountType: AccountType.Unknown,
  suspensionDetails: [],
  currentTermsAccepted: undefined,
  termsEnabled: undefined
};
