import * as React from "react";
import { Container } from "@material-ui/core";
import { Navigation } from "./Navigation";
import * as Styles from "./styles/StandardNavigationStyles";
import { AppContext, IInitialState } from "../../contexts/AppContext";

const StandardNavigation: React.FC<{}> = () => {
  const classes = Styles.StandardNavigationStyles();

  return (
    <AppContext.Consumer>
      {(context: IInitialState) => (
        <Container maxWidth={"lg"} className={classes.navigationContainer}>
          <Navigation lightTheme={false} context={context} />
        </Container>
      )}
    </AppContext.Consumer>
  );
};

export default StandardNavigation;
