import { makeStyles } from "@material-ui/core/styles";

export const SearchBarStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0, 2),
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  searchOptionListScroll: {
    maxHeight: "300px",
    overflow: "auto",
    marginTop: "8px",
  },
  searchOptionList: {
    zIndex: 999,
  },
  searchTextBox: {
    maxHeight: "40px;",
  },
  container: {
    width: "100%",
    backgroundColor: "#EDEDED",
  },
  box: {
    width: "100%",
    backgroundColor: "#EDEDED",
    paddingTop: "25px",
    marginBottom: "20px",
  },
  resetButton: {
    marginLeft: "8px",
  },
}));
