import * as React from "react";
import * as Styles from "../styles/SearchBarStyles";
import { ButtonGroup, Button, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ISearchBarOptionsProps } from "../../../interfaces/search/ISearchBarOptionProps";

export const SearchBarOption: React.FC<ISearchBarOptionsProps> = ({
  displayName,
  propertyName,
  disable,
  options,
  selectedItemName,
  onSelectedEvent,
}) => {
  const classes = Styles.SearchBarStyles();

  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  // const [selectedIndex, setSelectedIndex] = React.useState(
  //   Object.keys(options)
  //     .map(v => v.toLowerCase())
  //     .indexOf(selectedItemName.toLowerCase())
  // );
  const [selectedIndex, setSelectedIndex] = React.useState(() => {
    if (options) {
      const optionKeys = Object.keys(options);
      return optionKeys.map(v => v.toLowerCase()).indexOf(selectedItemName.toLowerCase());
    } else {
      // handle the case where options is null or undefined
      return -1; // or any default value you want
    }
  });
  const [selectedItem, setSelectedItem] = React.useState(displayName);

  React.useEffect(() => {
    const optionName: Array<string> = Object.keys(options);
    var index = optionName.map(v => v.toLowerCase()).indexOf(selectedItemName.toLowerCase());
    setSelectedIndex(index);
    if (index > -1) {
      setSelectedItem(`${optionName[index]}`);
    } else {
      if (selectedItemName === "") {
        setSelectedItem(displayName);
      } else {
        setSelectedItem(selectedItemName);
        setSelectedIndex(1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItemName]);

  function handleMenuItemClick(event: any, index: any) {
    var selected = "";
    setSelectedIndex(index);

    if (index === -1) {
      setSelectedItem(displayName);
    } else {
      selected = Object.keys(options)[index];
      setSelectedItem(selected);
    }
    setOpen(false);
    onSelectedEvent(propertyName, selected);
  }

  function handleToggle() {
    setOpen(prevOpen => !prevOpen);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      <ButtonGroup
        size="medium"
        variant={selectedIndex > -1 ? "contained" : "outlined"}
        color={selectedIndex > -1 ? "primary" : "default"}
        ref={anchorRef}
        aria-label=""
        disabled={disable}
        style={{ height: "40px", backgroundColor:"white" }}
      >
        <Button onClick={handleToggle}>
          {displayName === "Category" && selectedItem !== "Category" && "Category"} {selectedItem}
        </Button>
        <Button
          color={selectedIndex > -1 ? "primary" : "default"}
          size="small"
          aria-owns={open ? "menu-list-grow" : ""}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <ExpandMoreIcon />
        </Button>
      </ButtonGroup>
      <Popper className={classes.searchOptionList} open={open} anchorEl={anchorRef.current} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper id="menu-list-grow">
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList className={classes.searchOptionListScroll}>
                  <MenuItem key={-1} selected={selectedIndex === -1} onClick={event => handleMenuItemClick(event, -1)}>
                    All
                  </MenuItem>
                  {Object.keys(options).map((option, index) => (
                    <MenuItem key={index} selected={index === selectedIndex} onClick={event => handleMenuItemClick(event, index)}>
                      {displayName === "Category" && "Category"} {option}{" "}
                      {Object.values(options)[index] > 0 ? `(${Object.values(options)[index]})` : ""}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
